import { mix } from "@theme-ui/color";

export const basePallet = {
  grey: {
    0: "#FFFFFF",
    50: "#fafafa",
    100: "#f6f6f6",
    200: "#efefef",
    300: "#e2e2e2",
    400: "#cfcfcf",
    500: "#b4b4b4",
    600: "#919191",
    700: "#646464",
    800: "#3a3a3a",
    900: "#131313",
    1000: "#121212",
  },
};

export const basePalletDark = {
  grey: {
    0: "#121212",
    50: "#131313",
    100: "#3a3a3a",
    200: "#646464",
    300: "#919191",
    400: "#b4b4b4",
    500: "#cfcfcf",
    600: "#e2e2e2",
    700: "#efefef",
    800: "#f6f6f6",
    900: "#fafafa",
    1000: "#FFFFFF",
  },
};

export const colors = {
  ...basePallet,
  text: {
    __default: "#000000DE",
    heading: "#000000",
    secondary: "#00000099",
    disabled: "#00000061",
  },

  // Primary
  primary: {
    __default: "#b02909",
    dark: "#7b1c06",
    light: "#f3380e",
    contrastText: basePallet.grey[0],
    hover: "#b029090A",
    active: "#b029093D",
    selected: "#b0290914",
    border: "#b0290980",
    activeMix: mix("#b02909", "#FFFFFF", 0.24),
  },

  // Info
  info: {
    __default: "#3860be",
    dark: "#2b4a93",
    light: "#6a8ad3",
    darkText: "#152447",
    lightBackground: "#e7ecf8",
    constrastText: "#FFFFFF",
  },

  // Error
  error: {
    __default: "#b02909",
    dark: "#7b1c06",
    light: "#f3380e",
    contrastText: "#FFFFFF",
    darkText: "#571404",
    lightBackground: "#fee7e2",
  },

  // Warning
  warning: {
    __default: "#DEAD42",
    dark: "#c69222",
    light: "#e9c77f",
    contrastText: "#121212",
    darkText: "#4e3a0e",
    lightBackground: "#faf4e5",
  },

  // Success
  success: {
    __default: "#3C6F3E",
    dark: "#284a29",
    light: "#559d57",
    contrastText: "#FFFFFF",
    darkText: "#203c21",
    lightBackground: "#ebf4eb",
  },

  shadows: {
    elevation1dp: "#1212121F",
    elevation3dp: "#12121233",
    elevation5dp: "#12121247",
  },

  // Other
  background: "#FFFFFF",
  componentBG1: "#FFFFFF",
  componentBG2: "#FFFFFF",
  componentBG3: "#FFFFFF",
  backgroundDisabled: "#F4F1ED",
  lineDetail: "#00000033",
  systemBlack: basePallet.grey[1000],
  systemWhite: basePallet.grey[0],
  backdrop: "#1313138A",

  menuSliderGradientLeft: `linear-gradient(90deg, ${basePallet.grey[50]} 0%, ${basePallet.grey[50]}00 100%)`,
  menuSliderGradientRight: `linear-gradient(270deg, ${basePallet.grey[50]} 0%, ${basePallet.grey[50]}00 100%)`,
  modes: undefined,
  // modes: {
  //   dark: {
  //     ...basePalletDark,
  //     text: {
  //       __default: "#FFFFFFDE",
  //       heading: "#FFFFFF",
  //       secondary: "#FFFFFF99",
  //       disabled: "#FFFFFF61",
  //     },

  //     background: "#121212",

  //     // Primary
  //     primary: {
  //       __default: "#CEB780",
  //       dark: "#e2d4b4",
  //       light: "#bea058",
  //       contrastText: "#121212",
  //       hover: "#CEB7800A",
  //       active: "#CEB7803D",
  //       selected: "#CEB78014",
  //       border: "#CEB78080",
  //     },

  //     // Info
  //     info: {
  //       __default: "#428bca",
  //       dark: "#7aadda",
  //       light: "#2e6ea5",
  //       contrastText: "#121212",
  //       darkText: "#e7f0f8",
  //       lightBackground: "#143048",
  //     },

  //     error: {
  //       __default: "#FF8A80",
  //       dark: "#ffccc7",
  //       light: "#ff5748",
  //       contrastText: "#121212",
  //       darkText: "#ffe3e0",
  //       lightBackground: "#5c0800",
  //     },

  //     warning: {
  //       __default: "#FFE57F",
  //       dark: "#fff4c6",
  //       light: "#ffda47",
  //       contrastText: "#121212",
  //       darkText: "#fff9e0",
  //       lightBackground: "#5c4900",
  //     },

  //     success: {
  //       __default: "#69F0AE",
  //       dark: "#aaf6d1",
  //       light: "#36eb93",
  //       contrastText: "#121212",
  //       darkText: "#e3fcf0",
  //       lightBackground: "#08532f",
  //     },

  //     shadows: {
  //       elevation1dp: "#1212121F",
  //       elevation3dp: "#12121233",
  //       elevation5dp: "#12121247",
  //     },

  //     // Other
  //     componentBG1: "#1C1913",
  //     componentBG2: "#262013",
  //     componentBG3: "#302714",
  //     backgroundDisabled: "#8F816D",
  //     lineDetail: "#FFFFFF33",
  //     systemWhite: "#FFFFFF",
  //     systemBlack: "#121212",
  //     backdrop: "#1313138A",
  //     menuSliderGradientLeft: `linear-gradient(90deg, ${basePalletDark.grey[0]} 0%, ${basePalletDark.grey[0]}00 100%)`,
  //     menuSliderGradientRight: `linear-gradient(270deg, ${basePalletDark.grey[0]} 0%, ${basePalletDark.grey[0]}00 100%)`,
  //   },
  // },
};

export const shadows = {
  elevation1dp: "0px 2px 12px 0px var(--theme-ui-colors-shadows-elevation1dp)",
  elevation3dp: "0px 8px 24px 0px var(--theme-ui-colors-shadows-elevation3dp)",
  elevation5dp: "0px 16px 40px 0px var(--theme-ui-colors-shadows-elevation5dp)",
};
