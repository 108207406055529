export const fonts = {
  body: "Akkurat",
  heading: "Didot",
  subtitle: "IBMPlexSans-SemiBold",
  button: "IBMPlexSans-SemiBold",
  input: "Akkurat",
};

export const lineHeights = {
  body: 1.5,
  heading: 1.125,
};

export const text = {
  heading1: {
    color: "text.heading",
    fontFamily: "Didot",
    fontSize: 24,
    "--line-height": 1.2,
    lineHeight: "var(--line-height)",
    letterSpacing: "-1.5px",
  },
  heading2: {
    color: "text.heading",
    fontFamily: "Didot",
    fontSize: 15,
    "--line-height": 1.25,
    lineHeight: "var(--line-height)",
    letterSpacing: "-0.5px",
  },
  heading3: {
    color: "text.heading",
    fontFamily: "Didot",
    fontSize: 12,
    "--line-height": 1.3,
    lineHeight: "var(--line-height)",
    letterSpacing: "0px",
  },
  heading4: {
    color: "text.heading",
    fontFamily: "Didot",
    fontSize: 8.5,
    "--line-height": 1.4,
    lineHeight: "var(--line-height)",
    letterSpacing: "0.25px",
  },
  heading5: {
    color: "text.heading",
    fontFamily: "Didot",
    fontSize: 6,
    "--line-height": 1.5,
    lineHeight: "var(--line-height)",
    letterSpacing: "0px",
  },
  heading6: {
    color: "text.heading",
    fontFamily: "Didot",
    fontSize: 5,
    "--line-height": 1.5,
    lineHeight: "var(--line-height)",
    letterSpacing: "0.15px",
  },
  subtitle1: {
    color: "text.heading",
    fontFamily: "IBMPlexSans-SemiBold",
    fontSize: 4,
    "--line-height": 1.5,
    lineHeight: "var(--line-height)",
    letterSpacing: "0.15px",
  },
  subtitle2: {
    color: "text.heading",
    fontFamily: "IBMPlexSans-SemiBold",
    fontSize: 3.5,
    "--line-height": 1.5,
    lineHeight: "var(--line-height)",
    letterSpacing: "0.15px",
  },
  body1: {
    color: "text",
    fontFamily: "Akkurat",
    fontSize: 4,
    "--line-height": 1.5,
    lineHeight: "var(--line-height)",
    letterSpacing: "0.15px",
  },
  body2: {
    color: "text",
    fontFamily: "Akkurat",
    fontSize: 3.5,
    "--line-height": 1.5,
    lineHeight: "var(--line-height)",
    letterSpacing: "0.15px",
  },
  body3: {
    color: "text",
    fontFamily: "Akkurat",
    fontSize: 3,
    "--line-height": 1.5,
    lineHeight: "var(--line-height)",
    letterSpacing: "0.15px",
  },
  button1: {
    color: "primary.contrastText",
    fontFamily: "IBMPlexSans-SemiBold",
    fontSize: 4,
    "--line-height": 1.5,
    lineHeight: "var(--line-height)",
    letterSpacing: "0px",
  },
  button2: {
    color: "primary.contrastText",
    fontFamily: "IBMPlexSans-SemiBold",
    fontSize: 3.75,
    "--line-height": 1.5,
    lineHeight: "var(--line-height)",
    letterSpacing: "0px",
  },
  button3: {
    color: "primary.contrastText",
    fontFamily: "IBMPlexSans-SemiBold",
    fontSize: 3.5,
    "--line-height": 1.5,
    lineHeight: "var(--line-height)",
    letterSpacing: "0px",
  },
  caption: {
    color: "text",
    fontFamily: "Akkurat",
    fontSize: 3,
    "--line-height": 1.2,
    lineHeight: "var(--line-height)",
    letterSpacing: "0.4px",
  },
  overline: {
    color: "text",
    fontFamily: "Akkurat",
    fontSize: 3,
    "--line-height": 2.5,
    lineHeight: "var(--line-height)",
    letterSpacing: "1px",
  },
};
