import "./fonts.css";
import { colors, shadows } from "./colors";
import { text, fonts, lineHeights } from "./typography";
import { radii, zIndices } from "./sizeAndSpace";

import { merge } from "theme-ui";

import baseTheme from "@alliants/gatsby-theme-axp-recommends-core/src/gatsby-plugin-theme-ui";

const themeBase = merge(baseTheme, {
  colors,
  shadows,

  // Typography
  fonts,
  lineHeights,
  text,

  // Space and Size
  zIndices,
});

export default themeBase;
