export const zIndices = {
  negative: -1,
  low: 10,
  "stiky-menu": 15,
  menu: 20,
  nav: 110,
  backdrop: 200,
  modal: 250,
  "modal-button": 270,
};
